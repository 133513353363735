// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("jquery")
require("tablesorter")
//require("tablesorter/dist/js/widgets/widget-cssStickyHeaders.min.js")
require("select2")


var rome=require("rome");
import '../src/styles/js_styles.scss'


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", () => {
  var $table = $('table.tablefilter').tablesorter({
    widgets: ["zebra", "filter", "savesort"],
    widgetOptions : {
      // filter_anyMatch replaced! Instead use the filter_external option
      // Set to use a jQuery selector (or jQuery object) pointing to the
      // external filter (column specific or any match)
      filter_external : '.search',
      // add a default type search to the first name column
      //filter_defaultFilter: { 1 : '~{query}' },
      // include column filters
      //filter_columnFilters: true,
      filter_placeholder: { search : 'Filter...' },
      filter_saveFilters : true,
      filter_reset: '.tablefilter-reset',
    },
  });
  $('select[multiple]').select2({
    placeholder: "Select an option..."
  });
  document.querySelectorAll('input.datetimepicker').forEach(
    function(i) {
      rome(i);
      i.setAttribute("autocomplete","off");
    });
  document.querySelectorAll('input.datepicker').forEach(
    function(i) {
      rome(i,{time: false});
      i.setAttribute("autocomplete","off");
    });
});